import getStoreCode from '@garmin/locale-storecode';

export default {
  state: {
    buyGarminEndpoint: '',
    wwwGarminEndpoint: '',
    locale: '',
    productId: '',
    defaultSku: '',
    storeCode: '',
    breadCrumbs: [],
    countryCode: '',
    cloudinaryCloudName: '',
    cloudinarySecureDistribution: null,
    seoAttributes: '',
    marketCategoryGlobal: '',
    productCategoryGlobal: '',
  },
  getters: {
    getBuyGarminEndpoint(state) {
      return state.buyGarminEndpoint;
    },
    getWwwGarminEndpoint(state) {
      return state.wwwGarminEndpoint;
    },
    getLocale(state) {
      return state.locale;
    },
    getProductId(state) {
      return state.productId;
    },
    getDefaultSku(state) {
      return state.defaultSku;
    },
    getStoreCode(state) {
      return state.storeCode;
    },
    getBreadCrumbs(state) {
      return state.breadCrumbs;
    },
    getProductPageApi(state, getters) {
      return `/${getters.getLocale}/p/api`;
    },
    getCountryCode(state) {
      return state.countryCode;
    },
    getCloudinaryCloudName(state) {
      return state.cloudinaryCloudName;
    },
    getCloudinarySecureDistribution(state) {
      return state.cloudinarySecureDistribution;
    },
    getSeoAttributes(state) {
      return state.seoAttributes;
    },
    getMarketCategoryGlobal(state) {
      return state.marketCategoryGlobal;
    },
    getProductCategoryGlobal(state) {
      return state.productCategoryGlobal;
    },
  },
  mutations: {
    setBuyGarminEndpoint(state, payload) {
      state.buyGarminEndpoint = payload;
    },
    setWwwGarminEndpoint(state, payload) {
      state.wwwGarminEndpoint = payload;
    },
    setLocale(state, payload) {
      state.locale = payload;
    },
    setProductId(state, payload) {
      state.productId = payload;
    },
    setDefaultSku(state, payload) {
      state.defaultSku = payload;
    },
    setStoreCode(state, payload) {
      state.storeCode = payload;
    },
    setBreadCrumbs(state, payload) {
      state.breadCrumbs = payload;
    },
    setCountryCode(state, payload) {
      state.countryCode = payload;
    },
    setCloudinaryCloudName(state, payload) {
      state.cloudinaryCloudName = payload;
    },
    setCloudinarySecureDistribution(state, payload) {
      state.cloudinarySecureDistribution = payload;
    },
    setSeoAttributes(state, payload) {
      state.seoAttributes = payload;
    },
    setMarketCategoryGlobal(state, payload) {
      state.marketCategoryGlobal = payload;
    },
    setProductCategoryGlobal(state, payload) {
      state.productCategoryGlobal = payload;
    },
  },
  actions: {
    setGlobalState({ commit }, payload) {
      const {
        buyGarminEndpoint,
        wwwGarminEndpoint,
        locale,
        productId,
        sku,
        breadCrumbs,
        cloudName,
        secureDistribution,
        seoAttributes,
        marketCategoryGlobal,
        productCategoryGlobal,
      } = payload;

      // getCountry code for the Pricing Proxy calls
      const countryCode = locale.split('-', 2)[1] || '';

      const storeCode = getStoreCode(locale);

      commit('setBuyGarminEndpoint', buyGarminEndpoint);
      commit('setWwwGarminEndpoint', wwwGarminEndpoint);
      commit('setLocale', locale);
      commit('setProductId', productId);
      commit('setDefaultSku', sku);
      commit('setStoreCode', storeCode);
      commit('setBreadCrumbs', breadCrumbs);
      commit('setCountryCode', countryCode);
      commit('setCloudinaryCloudName', cloudName);
      commit('setCloudinarySecureDistribution', secureDistribution);
      commit('setSeoAttributes', seoAttributes);
      commit('setMarketCategoryGlobal', marketCategoryGlobal);
      commit('setProductCategoryGlobal', productCategoryGlobal);
    },
  },
};
