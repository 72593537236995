<script setup>
/* Imports */
import { inject } from 'vue';

/* Props */
const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
});

const state = props.show ? props : inject('state');
</script>

<template>
  <div>
    <div
      v-show="state?.show"
      class="app__tab-loader"
    >
      <g-loader type="default" />
    </div>
  </div>
</template>

<style lang="scss">
.app__tab-loader {
  width: 110px;
  padding: 1rem 0;
  margin: 0 auto;
}
</style>
