/* Helpers */
import {
  parseSidebarCustomLinks,
  request,
  priceTemplateBuilder,
  templateBuilder,
} from '../helpers/mainHelpers';

export default {
  state: {
    quickLinks: {},
    additionalContainer: '',
    compatibleProducts: {},
    compatibleProductsPrices: {},
    crossSellProducts: {},
    crossSellProductsPrices: {},
    upSellProducts: {},
    upSellProductsPrices: {},
    showSidebar: false,
    compatibleProductsLoaded: false,
    crossSellsLoaded: false,
    upSellsLoaded: false,
  },
  getters: {
    getShowSidebar(state) {
      return state.showSidebar;
    },
    getSidebarQuickLinks(state) {
      return state.quickLinks;
    },
    getSidebarAdditional(state) {
      return state.additionalContainer;
    },
    getSidebarAdditionalContent(state, getters) {
      const additionalContainer = getters.getSidebarAdditional;
      return additionalContainer ? additionalContainer.content : '';
    },
    getShowSidebarAdditionalContent(state, getters) {
      const content = getters.getSidebarAdditionalContent;
      const showSidebar = getters.getShowSidebar;
      return content && showSidebar;
    },
    getSoftwareLink(state, getters) {
      const quicklinks = getters.getSidebarQuickLinks;
      return quicklinks.softwareLink;
    },
    getShowSoftwareLink(state, getters) {
      const quicklinks = getters.getSidebarQuickLinks;
      return quicklinks.showSoftware;
    },
    getCompatibleProducts(state) {
      return state.compatibleProducts;
    },
    getCompatibleProductsByProductId(state, getters) {
      return (productId) => getters.getCompatibleProducts[productId];
    },
    getActiveCompatibleProducts(state, getters) {
      const productId = getters.getProductId;
      const products = getters.getCompatibleProductsByProductId(productId);
      return products || [];
    },
    getHasActiveCompatibleProducts(state, getters) {
      return !!getters.getActiveCompatibleProducts.length;
    },
    getCompatibleProductPrices(state) {
      return state.compatibleProductsPrices;
    },
    getCompatibleProductPriceByUrl(state, getters) {
      return (priceUrl) => getters.getCompatibleProductPrices[priceUrl];
    },
    getCrossSellProducts(state) {
      return state.crossSellProducts;
    },
    getCrossSellProductsByProductId(state, getters) {
      return (productId) => getters.getCrossSellProducts[productId];
    },
    getActiveCrossSellProducts(state, getters) {
      const productId = getters.getProductId;
      const crossSellProducts = getters.getCrossSellProductsByProductId(productId);
      return crossSellProducts || [];
    },
    getCrossSellProductPrices(state) {
      return state.crossSellProductsPrices;
    },
    getCrossSellProductPriceByUrl(state, getters) {
      return (priceUrl) => getters.getCrossSellProductPrices[priceUrl];
    },
    getUpSellProducts(state) {
      return state.upSellProducts;
    },
    getUpSellProductsByProductId(state, getters) {
      return (productId) => getters.getUpSellProducts[productId];
    },
    getActiveUpSellProducts(state, getters) {
      const productId = getters.getProductId;
      const upSellProducts = getters.getUpSellProductsByProductId(productId);
      return upSellProducts || [];
    },
    getUpSellProductPrices(state) {
      return state.upSellProductsPrices;
    },
    getUpSellProductPriceByUrl(state, getters) {
      return (priceUrl) => getters.getUpSellProductPrices[priceUrl];
    },
    getManualsLink(state, getters) {
      const partNumber = getters.getPartNumber;
      const urlTemplate = getters.getManualsUrl;
      const locale = getters.getLocale;
      const localeParts = locale.split('-');

      if (!urlTemplate) {
        return null;
      }

      return templateBuilder(urlTemplate, {
        language: localeParts[0],
        country: localeParts[1],
        partNum: partNumber,
      });
    },
    getCompatibleProductsLoaded(state) {
      return state.compatibleProductsLoaded;
    },
    getCrossSellsLoaded(state) {
      return state.crossSellsLoaded;
    },
    getUpSellsLoaded(state) {
      return state.upSellsLoaded;
    },
  },
  mutations: {
    setCompatibleProduct(state, payload) {
      const { data, productId } = payload;
      const products = state.compatibleProducts[productId];

      state.compatibleProducts[productId] = products.map((item) => {
        if (item.productId === data.productId) {
          return data;
        }
        return item;
      });
    },
    setCompatibleProducts(state, payload) {
      const { data, productId } = payload;
      state.compatibleProducts = { ...state.compatibleProducts, [productId]: data };
    },
    setCompatibleProductPrice(state, payload) {
      const { data, priceUrl } = payload;
      state.compatibleProductsPrices = {
        ...state.compatibleProductsPrices,
        [priceUrl]: data,
      };
    },
    setCrossSellProduct(state, payload) {
      const { data, productId } = payload;
      const products = state.crossSellProducts[productId];

      state.crossSellProducts[productId] = products.map((item) => {
        if (item.productId === data.productId) {
          return data;
        }
        return item;
      });
    },
    setCrossSellProducts(state, payload) {
      const { data, productId } = payload;
      state.crossSellProducts = { ...state.crossSellProducts, [productId]: data };
    },
    setCrossSellProductPrice(state, payload) {
      const { data, priceUrl } = payload;
      state.crossSellProductsPrices = {
        ...state.crossSellProductsPrices,
        [priceUrl]: data,
      };
    },
    setUpSellProduct(state, payload) {
      const { data, productId } = payload;
      const products = state.upSellProducts[productId];

      state.upSellProducts[productId] = products.map((item) => {
        if (item.productId === data.productId) {
          return data;
        }
        return item;
      });
    },
    setUpSellProducts(state, payload) {
      const { data, productId } = payload;
      state.upSellProducts = { ...state.upSellProducts, [productId]: data };
    },
    setUpSellProductPrice(state, payload) {
      const { data, priceUrl } = payload;
      state.upSellProductsPrices = {
        ...state.upSellProductsPrices,
        [priceUrl]: data,
      };
    },
    setQuickLinks(state, payload) {
      state.quickLinks = payload;
    },
    setAdditionalContainer(state, payload) {
      state.additionalContainer = payload;
    },
    setShowSidebar(state, payload) {
      state.showSidebar = payload;
    },
    setCompatibleProductsLoaded(state, payload) {
      state.compatibleProductsLoaded = payload;
    },
    setCrossSellsLoaded(state, payload) {
      state.crossSellsLoaded = payload;
    },
    setUpSellsLoaded(state, payload) {
      state.upSellsLoaded = payload;
    },
  },
  actions: {
    setSidebar({ commit }, payload) {
      if (payload) {
        const { content } = payload.quickLinks;
        payload.quickLinks.content = parseSidebarCustomLinks(content);
        commit('setQuickLinks', payload.quickLinks);
        commit('setAdditionalContainer', payload.additionalContainer);
        commit('setShowSidebar', true);
      } else {
        // Reset quickLinks and AdditionalContainer to default values if no payload data
        commit('setQuickLinks', {});
        commit('setAdditionalContainer', '');
      }
    },
    async callCompatibleProducts({ commit, getters, rootGetters }) {
      const { getCustomerGroups } = rootGetters;
      const {
        getProductId,
        getCompatibleProducts,
        getLocale,
        getBuyGarminEndpoint,
        getCompatibleProductsJsonUrl,
      } = getters;
      const localeParts = getLocale.split('-');

      if (!getCompatibleProductsJsonUrl || getCompatibleProducts[getProductId]) {
        return;
      }

      const url = templateBuilder(getCompatibleProductsJsonUrl, {
        productId: getProductId,
        country: localeParts[1],
        locale: getLocale,
      });

      const data = await request({
        url,
      }).catch(() => {
        throw new Error('Unable to call compatibleProducts service');
      });

      const compatibleProducts = data?.associationProducts;

      if (!data || !Array.isArray(compatibleProducts || !compatibleProducts?.length)) {
        return;
      }

      const productsCache = {
        productId: getProductId,
        data: compatibleProducts,
      };

      // Commit the compatible products after fetching
      commit('setCompatibleProducts', productsCache);

      const queryParams = new URLSearchParams();
      queryParams.append('locale', getLocale);
      /**
         * If there are multiple customerGroups, they will be divided by a |
         * new URLSearchParams() will convert | to %7C which the pricing APIs expect when there are multiple customer groups
         */
      queryParams.append('customerGroup', getCustomerGroups);
      const locationHref = window?.location?.href;
      if (locationHref.includes('cdncache=false')) queryParams.append('cdncache', false);

      const compatibleProductsPricingRequests = compatibleProducts.map(async (product) => {
        // Make the API call for the pricing proxy
        const priceUrl = `${getBuyGarminEndpoint}/pricing-proxy-services/countries/${localeParts[1]}/skus/${product.partNumber}/price?${queryParams}`;

        // Check if product price has already been called and cached
        // returning null indicates the price was not called
        const cachedPrice = getters.getCompatibleProductPriceByUrl(priceUrl);

        // if the price call is already cached, return that, else call to get price
        const productPrice = cachedPrice || await request({
          url: priceUrl,
        }).catch(() => {
          // eslint-disable-next-line
          console.error('Error calling pricing proxy service');
        });

        // Set price to empty object if no price returns
        if (!productPrice) {
          product.priceObj = {};
        }

        const { listPrice, salePrice } = productPrice;

        if (productPrice.listPrice) {
          productPrice.formattedPrice = priceTemplateBuilder(listPrice);
        }

        if (productPrice.salePrice) {
          productPrice.formattedSalePrice = priceTemplateBuilder(salePrice);
        }

        const newPrice = {
          data: productPrice,
          priceUrl,
        };

        // Add the price to the product
        product.priceObj = productPrice || {};

        // Commit the product again with the price updated
        commit('setCompatibleProduct', {
          productId: getProductId,
          data: product,
        });

        // Cache product price
        commit('setCompatibleProductPrice', newPrice);
      });

      try {
        await Promise.all(compatibleProductsPricingRequests);
        commit('setCompatibleProductsLoaded', true);
      } catch (e) {
        console.error(`Unable to fetch Compatible Products pricing requests: ${e}`);
      }
    },
    async callCrossSellProducts({ commit, getters, rootGetters }) {
      const { getCustomerGroups } = rootGetters;
      const {
        getProductId, getCrossSellProducts, getLocale, getBuyGarminEndpoint, getCrossSellsJsonUrl,
      } = getters;
      const localeParts = getLocale.split('-');

      if (!getCrossSellsJsonUrl || getCrossSellProducts[getProductId]) {
        return;
      }

      const url = templateBuilder(getCrossSellsJsonUrl, {
        productId: getProductId,
        country: localeParts[1],
        locale: getLocale,
      });

      const data = await request({
        url,
      }).catch(() => {
        throw new Error('Unable to call crossSellProducts service');
      });

      const crossSellProducts = data?.associationProducts;

      if (!data || !Array.isArray(crossSellProducts || !crossSellProducts?.length)) {
        return;
      }

      const productsCache = {
        productId: getProductId,
        data: crossSellProducts,
      };

      // Commit the cross sell products after fetching
      commit('setCrossSellProducts', productsCache);

      // Continue to get the prices
      const crossSellsPricingRequests = crossSellProducts.map(async (item) => {
        const queryParams = new URLSearchParams();
        queryParams.append('locale', getLocale);
        /**
         * If there are multiple customerGroups, they will be divided by a |
         * new URLSearchParams() will convert | to %7C which the pricing APIs expect when there are multiple customer groups
         */
        queryParams.append('customerGroup', getCustomerGroups);

        const locationHref = window?.location?.href;
        if (locationHref.includes('cdncache=false')) queryParams.append('cdncache', false);
        // Make the API call for the pricing proxy
        const priceUrl = `${getBuyGarminEndpoint}/pricing-proxy-services/countries/${localeParts[1]}/skus/${item.partNumber}/price?${queryParams}`;

        // Check if product price has already been called and cached
        // returning null indicates the price was not called
        const cachedPrice = getters.getCrossSellProductPriceByUrl(priceUrl);

        // if the price call is already cached, return that, else call to get price
        const itemPrice = cachedPrice || await request({
          url: priceUrl,
        }).catch(() => {
          // eslint-disable-next-line
          console.error('Error calling pricing proxy service');
        });

        // Set price to empty object if no price returns
        if (!itemPrice) {
          item.priceObj = {};
        }

        const { listPrice, salePrice } = itemPrice;

        if (itemPrice.listPrice) {
          itemPrice.formattedPrice = priceTemplateBuilder(listPrice);
        }

        if (itemPrice.salePrice) {
          itemPrice.formattedSalePrice = priceTemplateBuilder(salePrice);
        }

        const newPrice = {
          data: itemPrice,
          priceUrl,
        };

        // Add the price to the product
        item.priceObj = itemPrice || {};

        // Commit the product again with the price updated
        commit('setCrossSellProduct', {
          productId: getProductId,
          data: item,
        });

        // Cache product price
        commit('setCrossSellProductPrice', newPrice);
      });

      try {
        await Promise.all(crossSellsPricingRequests);
        commit('setCrossSellsLoaded', true);
      } catch (e) {
        console.error(`Unable to fetch Cross Sells pricing requests: ${e}`);
      }
    },
    async callUpSellProducts({ commit, getters, rootGetters }) {
      const { getCustomerGroups } = rootGetters;
      const {
        getProductId, getUpSellProducts, getLocale, getBuyGarminEndpoint, getUpSellsJsonUrl,
      } = getters;
      const localeParts = getLocale.split('-');

      if (!getUpSellsJsonUrl || getUpSellProducts[getProductId]) {
        return;
      }

      const url = templateBuilder(getUpSellsJsonUrl, {
        productId: getProductId,
        country: localeParts[1],
        locale: getLocale,
      });

      const data = await request({
        url,
      }).catch(() => {
        throw new Error('Unable to call upSellProducts service');
      });

      const upSellProducts = data?.associationProducts;

      if (!data || !Array.isArray(upSellProducts || !upSellProducts?.length)) {
        return;
      }

      const productsCache = {
        productId: getProductId,
        data: upSellProducts,
      };

      // Commit the upsell products after fetching
      commit('setUpSellProducts', productsCache);

      // Continue to get the prices
      const upSellsPricingRequests = upSellProducts.map(async (item) => {
        const queryParams = new URLSearchParams();
        queryParams.append('locale', getLocale);
        /**
         * If there are multiple customerGroups, they will be divided by a |
         * new URLSearchParams() will convert | to %7C which the pricing APIs expect when there are multiple customer groups
         */
        queryParams.append('customerGroup', getCustomerGroups);

        const locationHref = window?.location?.href;
        if (locationHref.includes('cdncache=false')) queryParams.append('cdncache', false);
        // Make the API call for the pricing proxy
        const priceUrl = `${getBuyGarminEndpoint}/pricing-proxy-services/countries/${localeParts[1]}/skus/${item.partNumber}/price?${queryParams}`;

        // Check if product price has already been called and cached
        // returning null indicates the price was not called
        const cachedPrice = getters.getUpSellProductPriceByUrl(priceUrl);

        // if the price call is already cached, return that, else call to get price
        const itemPrice = cachedPrice || await request({
          url: priceUrl,
        }).catch(() => {
          // eslint-disable-next-line
          console.error('Error calling pricing proxy service');
        });

        // Set price to empty object if no price returns
        if (!itemPrice) {
          item.priceObj = {};
        }

        const { listPrice, salePrice } = itemPrice;

        if (itemPrice.listPrice) {
          itemPrice.formattedPrice = priceTemplateBuilder(listPrice);
        }

        if (itemPrice.salePrice) {
          itemPrice.formattedSalePrice = priceTemplateBuilder(salePrice);
        }

        const newPrice = {
          data: itemPrice,
          priceUrl,
        };

        // Add the price to the product
        item.priceObj = itemPrice || {};

        // Commit the product again with the price updated
        commit('setUpSellProduct', {
          productId: getProductId,
          data: item,
        });

        // Cache product price
        commit('setUpSellProductPrice', newPrice);
      });

      try {
        await Promise.all(upSellsPricingRequests);
        commit('setUpSellsLoaded', true);
      } catch (e) {
        console.error(`Unable to fetch Up Sells pricing requests: ${e}`);
      }
    },
  },
};
