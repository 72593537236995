<script setup>
/* Helpers */
import { replaceSuperScript } from '../helpers/mainHelpers';

/* Props */
const props = defineProps({
  content: {
    type: String,
    default: '',
  },
});
</script>

<template>
  <div
    id="js__product__marketing-summary"
    class="app__product__marketing-summary"
    v-html="replaceSuperScript(props.content)"
  />
</template>
