/**
 * Sorts an array of objects by the order property
 * @param {Array} objArray - Array of objects to sort
 * @returns {Array}        - Sorted array of objects
 *
 * @example
 * sortOrder([{order: 1}, {order: 2}]) => [{order: 1}, {order: 2}]
 *
 */
export const sortOrder = (objArray) => objArray.sort((a, b) => ((a.order > b.order) ? 1 : -1));
