import { sanitizeAnalyticsString } from '@garmin/analytics';

/**
 * @param {string} productName - The string to parse.
 * @returns {string}   - The first word of the string.
 *
 * @example
 * getCategory3Data('fēnix® 7 Pro Series') => 'fenix'
 */
export const getCategory3Data = (productName) => {
  if (!productName || productName === 'NA' || typeof productName !== 'string') {
    return ['NA'];
  }
  const productNameFirstWord = productName.split(' ')[0];

  return [sanitizeAnalyticsString(productNameFirstWord)];
};

export default getCategory3Data;
