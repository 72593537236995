<script setup>
/* Imports */
import { computed } from 'vue';

/* Helpers */
import { replaceSuperScript } from '../helpers/mainHelpers';

/* Props */
const props = defineProps({
  shippingDisclaimerText: {
    type: String,
    default: '',
  },
});

/* Computed */
const computedShippingDisclaimerText = computed(() => replaceSuperScript(props.shippingDisclaimerText));
</script>

<template>
  <p
    class="app__product__processing"
    v-html="computedShippingDisclaimerText"
  />
</template>
