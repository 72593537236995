/* Imports */
import axios from 'axios';
import axiosRetry from 'axios-retry';

/**
 * This is a wrapper helper to perform http requests using Axios.
 * You simply import it into your component and use it like this:
 * request({
 *  url: <THE_API_URL>              // This is required and will fail if not sent
 *  method: 'GET' / 'POST' / 'PUT'  // By default it is GET
 *  payload: ....                   // Used only for POST / PUT
 *  headers: {}                     // Custom headers that you want to set
 * });
 *
 * The function will return the output of the request
 * and you don't have to handle any Promises (usually).
 *
 *
 */

axiosRetry(axios, {
  retries: 3,
  shouldResetTimeout: true,
  retryCondition: (err) => {
    // Don't retry the cart API calls
    if (err?.request?.responseURL?.toLowerCase()?.indexOf('cart') !== -1) return false;
    return true;
  },
  retryDelay: () => 1000,
});

// Define a function to throw an error with a message
const throwError = (msg) => {
  throw new Error(`${msg}`);
};

/**
 * Make a global config object with default values.
 *
 * @param {Object} [config={}] - Optional config override.
 * @return {Object}            - Merged configuration object with default values.
 */
const makeConfig = (config) => {
  const defaults = {
    url: '', // @param {String} URL to request (required)
    method: 'GET', // @param {String} HTTP request method (default is GET)
    payload: undefined, // @param {String} Object representing the payload (used for POST/PUT)
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (!config.url) throwError('url is required to make request');

  return {
    ...defaults,
    ...config,
  };
};

// Add a cache-bypass parameter to the URL if needed
const setCacheBypass = (url) => {
  // Add cdncache param to all calls to bypass cache if set in the page url
  let newUrl = url;
  if (window.location.search.includes('cdncache=false')) {
    const urlObj = new URL(url);
    urlObj.searchParams.set('cdncache', 'false');
    newUrl = urlObj.href;
  }

  return newUrl;
};

/**
 * The main request functionality to make HTTP requests using Axios.
 *
 * @param {Object} config                - Configuration for the HTTP request.
 * @param {String} config.url            - Required URL to request.
 * @param {String} [config.method]       - The request method (GET by default).
 * @param {String} [config.payload]      - Object representing the payload (used for POST/PUT).
 * @param {String} [config.headers]      - Object with custom headers.
 * @param {String} [config.cacheRequest] - Boolean flag if we want to cache the request.
 * @param {String} [config.cacheAge]     - Cache duration length.
 * @return {Object}                      - Response data from the HTTP request.
 */
export const request = async (config = {}) => {
  const mergedConfig = makeConfig(config);
  const {
    method,
    url,
    headers,
    payload,
  } = mergedConfig;

  const newUrl = setCacheBypass(url);

  const response = await axios
    .request({
      method,
      url: newUrl,
      headers,
      data: payload,
    })
    .catch((error) => {
      /* eslint-disable no-console */
      if (error.response) {
        // Handle errors for responses with non-2xx status codes
        const errorData = {
          code: error?.code,
          message: error?.message,
          status: error.response?.status,
          statusText: error.response?.statusText,
          headers: error.response?.headers,
        };

        console.warn(`Response error calling service ${url}`);
        console.warn(errorData);
      } else if (error.request) {
        /**
         * The request was made but no response was received
         * This error is most commonly caused by a bad/spotty network,
         * a hanging backend that does not respond instantly to each request,
         * unauthorized or cross-domain requests, and lastly if the backend API returns an error.
         */
        const errorData = {
          code: error?.code,
          message: error?.message,
          status: error?.status,
          stack: error?.stack,
        };

        console.warn(`Request error calling service ${url}`);
        console.warn(errorData);
      }
      throwError(`Unable to call service - ${error.message}`);
    });

  return response.data;
};
