/**
 * Constructs a price template with the provided parameters.
 * @param {Object} price - An object containing various price-related properties.
 * @returns {string}     - The formatted price template as an HTML string.
 */
export const priceTemplateBuilder = (price) => {
  if (price) {
    const {
      prefix,
      suffix,
      currencyCode,
      currencySymbol,
      wholeUnitAmount,
      decimalSeparator,
      tenthDigit,
      hundredthDigit,
      template,
    } = price;

    /**
     * Formats a template string by replacing placeholders with provided parameters.
     * @param {string} templateString - The template string containing placeholders.
     * @param {Object} params         - An object containing key-value pairs for replacing placeholders.
     * @returns {string}              - The formatted template string with placeholders replaced.
     */
    const templateFormatter = (templateString, params) => {
      let formattedTemplate = templateString;
      const keys = Object.keys(params);

      keys.forEach((key) => {
        formattedTemplate = formattedTemplate.replace(`{${key}}`, params[key]);
      });

      // clean up any remaining template placeholders
      // remove optional space before curly brackets match
      // remove new lines
      // remove extra whitespace
      formattedTemplate = formattedTemplate
        .replace(/(\s?\{.*?\})/g, '')
        .replace(/\r?\n|\r/g, '')
        .replace(/ /g, '');

      return formattedTemplate; // remove any extra whitespace
    };

    /**
     * Constructs the amount portion of the price template.
     * @param {string} templateString - The template string for the amount part.
     * @returns {string}              - The HTML-formatted amount portion of the price.
     */
    const constructAmount = (templateString) => {
      const formattedAmount = `<span class="amount">${templateFormatter(templateString, {
        wholeUnitAmount,
        decimalSeparator,
        tenthDigit,
        hundredthDigit,
      })}</span>`;
      return formattedAmount;
    };

    // Wrap specific template parts with the html classes for styling
    const amountHtml = constructAmount(template);
    const currencySymbolHtml = `<span class="currency-sign">${currencySymbol}</span>`;
    const currencyCodeHtml = `<span class="currency-code">${currencyCode}</span>`;

    // If prefix and suffix are undefined, set them as empty strings
    const formattedPrefix = prefix || '';
    const formattedSuffix = suffix || '';

    /**
     * Formats the complete price template by replacing placeholders with specific content.
     * @param {string} templateString - The original template string containing placeholders.
     * @returns {string}              - The cleaned and formatted price template.
     */
    const formattedTemplate = (templateString) => {
      const cleanedTemplate = templateString
        .replace('{prefix}', formattedPrefix)
        .replace('{wholeUnitAmount}', amountHtml)
        .replace('{currencySymbol}', currencySymbolHtml)
        .replace('{currencyCode}', currencyCodeHtml)
        .replace('{decimalSeparator}', '')
        .replace('{tenthDigit}', '')
        .replace('{hundredthDigit}', '')
        .replace('{suffix}', formattedSuffix);

      return cleanedTemplate;
    };

    return formattedTemplate(template);
  }

  // Return a placeholder for when the price is not provided.
  return '<span class="no-price"></span>';
};
