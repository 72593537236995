<script setup>
/* Props */
const props = defineProps({
  translations: {
    type: Object,
    default: () => ({}),
  },
  width: {
    type: [String, Number],
    default: '0',
  },
  height: {
    type: [String, Number],
    default: '0',
  },
});
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    :width="props.width"
    :height="props.height"
  >
    <title>{{ props.translations?.ITFE_PRODUCT_PAGES_BACK_TO_TOP }}</title>
    <circle
      fill="#FFF"
      stroke="#000"
      stroke-width="2"
      stroke-miterlimit="10"
      cx="50"
      cy="50"
      r="46.2"
    />
    <path
      fill="none"
      stroke="#000"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      d="M50 80.3v-59M35 36l15-16 15.2 16.2"
    />
  </svg>
</template>
