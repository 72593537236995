/* Helpers */
import { request, priceTemplateBuilder } from '../helpers/mainHelpers';

const initState = {
  shouldDisplay: false,
  isVisible: false,
  product: {
    name: '',
    variation: '',
    imageSrc: '',
    altText: '',
  },
  compatibleAccessories: {},
  compatibleAccessoriesPrices: {},
};

export default {
  state: { ...initState },
  getters: {
    getInterstitialShouldDisplay(state) {
      return state.shouldDisplay;
    },
    getInterstitialIsVisible(state) {
      return state.isVisible;
    },
    getInterstitialProductDetails(state) {
      return state.product;
    },
    getInterstitialProductDescription(state) {
      return `${state.product.name} - ${state.product.variation}`;
    },
    getInterstitialProductImage(state) {
      return state.product.imageSrc;
    },
    getInterstitialProductAltText(state) {
      return state.product.altText;
    },
    getInterstitialAccessories(state) {
      return (partNumber) => state.compatibleAccessories[partNumber];
    },
    getHasInterstitialAccessories(state, getters) {
      const partNumber = getters.getPartNumber;
      const items = getters.getInterstitialAccessories(partNumber) || [];
      return !!items.length;
    },
    getInterstitialAccessoryPrices(state) {
      return state.compatibleAccessoriesPrices;
    },
    getInterstitialAccessoryPriceByUrl(state, getters) {
      return (priceUrl) => getters.getInterstitialAccessoryPrices[priceUrl];
    },
  },
  mutations: {
    resetInterstitial(state) {
      state.shouldDisplay = initState.shouldDisplay;
      state.isVisible = initState.isVisible;
      state.product = { ...initState.product };
    },
    setInterstitialShouldDisplay(state, payload) {
      state.shouldDisplay = payload;
    },
    setInterstitialIsVisible(state, payload) {
      state.isVisible = payload;
    },
    setInterstitialProductDetails(state, payload) {
      state.product = payload;
    },
    setInterstitialAccessory(state, payload) {
      const { partNumber, data } = payload;
      const accessories = state.compatibleAccessories[partNumber];

      state.compatibleAccessories[partNumber] = accessories.map((item) => {
        if (item.partNumber === data.partNumber) {
          return data;
        }
        return item;
      });
    },
    setInterstitialAccessories(state, payload) {
      const { data, partNumber } = payload;
      state.compatibleAccessories = { ...state.compatibleAccessories, [partNumber]: data };
    },
    setInterstitialAccessoryPrice(state, payload) {
      const { data, priceUrl } = payload;
      state.compatibleAccessoriesPrices = {
        ...state.compatibleAccessoriesPrices,
        [priceUrl]: data,
      };
    },
  },
  actions: {
    resetInterstitial({ commit }) {
      commit('resetInterstitial');
    },
    setInterstitialShouldDisplay({ commit }, payload) {
      commit('setInterstitialShouldDisplay', payload);
    },
    setInterstitialIsVisible({ commit }, payload) {
      commit('setInterstitialIsVisible', payload);
    },
    setInterstitialProductDetails({ commit }, payload) {
      commit('setInterstitialProductDetails', payload);
    },
    async callInterstitialAccessories({ commit, getters, rootGetters }) {
      const { getCustomerGroups } = rootGetters;
      const {
        getBuyGarminEndpoint, getLocale, getCountryCode,
        getProductId, getPartNumber, getProductPageApi,
      } = getters;

      const url = `${getProductPageApi}/${getProductId}/compatibleProducts`;
      let accessories = getters.getInterstitialAccessories(getPartNumber);

      if (!accessories) {
        accessories = await request({
          url,
        });

        // Commit the accessories after fetching
        commit('setInterstitialAccessories', {
          partNumber: getPartNumber,
          data: accessories,
        });
      }

      // Continue to get the prices
      accessories.forEach(async (item) => {
        const queryParams = new URLSearchParams();
        queryParams.append('locale', getLocale);
        /**
         * If there are multiple customerGroups, they will be divided by a |
         * new URLSearchParams() will convert | to %7C which the pricing APIs expect when there are multiple customer groups
         */
        queryParams.append('customerGroup', getCustomerGroups);

        const locationHref = window?.location?.href;
        if (locationHref.includes('cdncache=false')) queryParams.append('cdncache', false);
        // Make the API call for the pricing proxy
        const priceUrl = `${getBuyGarminEndpoint}/pricing-proxy-services/countries/${getCountryCode}/skus/${item.partNumber}/price?${queryParams}`;

        // If accessory price has already been called and cached, return null
        // returning null indicates the price was not called
        const cachedPrice = getters.getInterstitialAccessoryPriceByUrl(priceUrl);

        // if the price call is already cached, return that, else call to get price
        const itemPrice = cachedPrice || await request({
          url: priceUrl,
        }).catch(() => {
          /* eslint-disable-next-line */
          console.error('Error calling pricing proxy service');
        });

        const { listPrice, salePrice } = itemPrice || {};

        if (itemPrice?.listPrice) {
          itemPrice.formattedPrice = priceTemplateBuilder(listPrice);
        }

        if (itemPrice?.salePrice) {
          itemPrice.formattedSalePrice = priceTemplateBuilder(salePrice);
        }

        const newPrice = {
          data: itemPrice || {},
          priceUrl,
        };

        // Add the price to the accessory
        item.priceObj = itemPrice || {};

        // Commit the accessory again with the price updated
        commit('setInterstitialAccessory', {
          partNumber: getPartNumber,
          data: item,
        });

        // Cache accessory price
        commit('setInterstitialAccessoryPrice', newPrice);
      });
    },
  },
};
