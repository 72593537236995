<script setup>
/* Imports */
import {
  onMounted,
  onUpdated,
} from 'vue';

/* Props */
const props = defineProps({
  widgetMetadata: {
    type: Object,
    default: () => ({}),
  },
});

/* Lifecycle hooks */
onMounted(() => {
  const klarnaAPI = props.widgetMetadata?.dataClientId ? 'https://js.klarna.com/web-sdk/v1/klarna.js' : '';

  // Construct the Klarna script tag and append it to the head of the document
  const klarnaScript = document.createElement('script');
  klarnaScript.setAttribute('async', '');
  klarnaScript.setAttribute('data-client-id', props.widgetMetadata?.dataClientId);
  klarnaScript.setAttribute('src', klarnaAPI);
  document.head.appendChild(klarnaScript);

  // Refresh placement for the Klarna widget
  window?.Klarna?.OnsiteMessaging?.refresh();
});

/* Listen for updates to the Klarna widget */
onUpdated(() => {
  // Refresh placement for the Klarna widget when the component updates
  window?.Klarna?.OnsiteMessaging?.refresh();
});
</script>

<template>
  <div class="app__product__klarna">
    <klarna-placement
      :data-key="props.widgetMetadata?.dataKey"
      :data-locale="props.widgetMetadata?.klarnaLocale"
      :data-purchase-amount="props.widgetMetadata?.dataPurchaseAmount"
    />
  </div>
</template>

<style lang="scss">
.app__product__klarna {
  margin: -1.5em auto 15px;
  max-width: fit-content;
  min-height: 22px;

  @include breakpoint('md') {
    margin: -1.5em 0 15px -10px;
  }
}
</style>
