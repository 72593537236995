/* Imports */
import { sanitizeAnalyticsString } from '@garmin/analytics';
import { mapGetters } from '../helpers/mainHelpers';
import { useRoute } from 'vue-router';

export const useGenericAnalyticsDataHelper = () => {
  /* Store - Getters */
  const {
    getCustomerGroups: customerGroups,
    getCurrencyCode: currencyCode,
  } = mapGetters();

  /* Routing */
  const route = useRoute();

  /**
   * Generates analytics data.
   *
   * @param {string} itemListId      - The ID of the item list.
   * @param {string} itemListName    - The name of the item list.
   * @returns {Object}               - The analytics data object.
   */
  const genericAnalyticsData = (itemListId, itemListName) => ({
    country_code: window?.globalAnalyticsData?.country_code || 'NA',
    language_code: window?.globalAnalyticsData?.language_code || 'NA',
    currency_code: currencyCode?.value || 'NA',
    page_name: sanitizeAnalyticsString(document?.title) || 'NA',
    page_type: [window?.globalAnalyticsData?.page_type || 'NA'],
    page_category: [window?.globalAnalyticsData?.page_category || 'NA'],
    page_subcategory: [window?.GarminAppBootstrap?.productCategory || 'NA'],
    login_status: window?.globalAnalyticsData?.login_status || 'NA',
    employee_flag: customerGroups?.value === 'US' ? 'true' : 'false',
    url_clean: window?.GarminAppBootstrap?.baseURL,
    cm_source: route?.query?.utm_source || 'NA',
    cm_medium: route?.query?.utm_medium || 'NA',
    cm_campaign: route?.query?.utm_campaign || 'NA',
    cm_term: route?.query?.utm_term || 'NA',
    cm_content: route?.query?.utm_content || 'NA',
    ...(itemListId !== undefined && { item_list_id: [itemListId] }),
    ...(itemListName !== undefined && {
      item_list_name: [`${window?.globalAnalyticsData?.product_name} >> ${itemListName}`],
    }),
    affiliation: [window?.globalAnalyticsData?.affiliation || 'NA'],
  });

  return { genericAnalyticsData };
};
