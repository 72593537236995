/**
 * This file contains helper functions that are used in the mapsData.js store file.
 * Isn't recommended to use these functions outside of the mapsData.js file.
 */

/* Imports */
import { replaceSuperScript } from './mainHelpers';

export const findCategory = (category, array) => array?.findIndex((obj) => obj?.category === category);

export const pushToMap = (mapsInRegion, productData, data, region, activityType) => {
  // We need to check if our mapsInRegion array already contains an object containing our category key
  const categoryIdx = findCategory(activityType, mapsInRegion);
  if (categoryIdx !== -1) {
    // Our MapsInRegion obj already contains category with at least one map
    mapsInRegion[categoryIdx]?.maps.push(data);
  } else {
    // This covers the case of new category with no maps
    mapsInRegion.push({
      category: activityType,
      maps: [data],
    });
  }
  productData.set(region, mapsInRegion);
};

export const setMapsData = (productData, item, defaultCategory, region, translations) => {
  let mapsInRegion = productData.get(region);
  // Needs to be in this format otherwise test will break
  const locale = window?.GarminAppBootstrap?.locale;
  const baseURL = window?.GarminAppBootstrap?.baseURL;
  const linkUrl = (locale && baseURL && item?.seoUrl) ? `${baseURL}/${locale}/${item?.seoUrl}` : item?.productId ?? '';

  // This points to the data structure called: `:card="mapCard"` in the MapsContainer.vue file
  const data = {
    id: item?.productId ?? '',
    imageUrl: item?.image ?? '',
    imageAltText: 'Map__Card__Image',
    linkUrl,
    heading: {
      content: replaceSuperScript(item?.displayName ?? ''),
      textAlign: 'left',
      size: '4',
      styleObject: {
        color: '#a832a2',
      },
    },
    ctas: [],
    customButtonStyle: {},
  };
  if (mapsInRegion === undefined) mapsInRegion = [];
  // Set the activity type to the category that is returned from the item's endpoint call
  pushToMap(mapsInRegion, productData, data, region ?? '', translations[item?.activityType] ?? '');
  /**
   * Push again to the data-structure but this time set the item category under
   * the defaultCategory (A.K.A 'View All') tab for the passed in region
   */
  pushToMap(mapsInRegion, productData, data, region ?? '', defaultCategory ?? '');
};

export const handleWorld = (productData, defaultCategory) => {
  // Get the maps under the region "World"
  const worldMaps = productData.get('World');
  if (worldMaps !== undefined) {
    // Delete the "World" region from data structure
    productData.delete('World');
    /**
     * Iterate through each of the maps stored in "world",
     * and copy each map to all of the other regions except "All"
     */
    worldMaps.forEach((item) => {
      // Get the category and maps
      const { category, maps } = item;
      // Iterate through productData
      productData.forEach((data, region, productDataMap) => {
        // Find the correct category in both worldMaps and productData
        if (region !== defaultCategory) {
          const categoryIdx = findCategory(category, data);
          // Format and set the changes
          if (categoryIdx >= 0) {
            data[categoryIdx] = { category, maps: data[categoryIdx]?.maps.concat(maps) };
          } else {
            /**
             * Create an object that contains the category and maps that are obtained from the worldMaps variable
             * This handles the case of a region in productData not having the found category
             */
            data.push({ category, maps });
          }
          productDataMap.set(region, data);
        }
      });
    });
  }
};

export const handleViewAll = (productData, defaultCategory) => {
  /**
   * This function will handle the case of removing "View All"
   * if a product only has 1 type of map for a region
   */
  productData.forEach((data, region, map) => {
    const numMaps = data?.length;
    // 2 represents the number of categories. Only run if there's exactly 2 categories
    if (numMaps === 2) {
      const mapData = map.get(region);
      // Get the index of the default category
      const defaultCategoryIndex = findCategory(defaultCategory, mapData);
      if (defaultCategoryIndex >= 0) {
        // This ensures that default category was found
        mapData.splice(defaultCategoryIndex, 1);
      }
    }
  });
};

// This function will sort the array alphabetically. It will also place the defaultCatgory at the correct position
export const sortRegionCategory = (array, defaultCategory, defaultCategoryPosition) => {
  if (array?.length > 1) {
    const findDefaultCategory = array.indexOf(defaultCategory);
    array.splice(findDefaultCategory, 1);
    array.sort();
    array.splice(defaultCategoryPosition, 0, defaultCategory);
  }
  return array ?? [];
};
