<script>
export default {
  name: 'ProductWarning',
  props: {
    content: {
      type: String,
      default: '',
    },
  },
};
</script>

<template>
  <div class="app__product__warning__content">
    <span
      class="app__product__warning"
      v-html="content"
    />
  </div>
</template>

<style lang="scss">
.app__product__warning {
  margin-top: 1em;

  &__content {
    display: inline-block;
  }
}
</style>
