/* Imports */
import { ref, onBeforeMount } from 'vue';

export const useBackToTopBtn = () => {
  /* State */
  const backToTopBtnRef = ref(null);
  const showBackToTopBtn = ref(false);

  /* Handle onScroll */
  const onScroll = () => {
    const backToTopOffset = backToTopBtnRef.value?.offsetTop;
    if (!backToTopOffset) return;
    showBackToTopBtn.value = (
      // For Safari
      document?.documentElement?.scrollTop > backToTopOffset
      // For Chrome, Firefox, IE and Opera
      || document?.body?.scrollTop > backToTopOffset
    );
  };

  /* Handle onBeforeMount */
  onBeforeMount(() => {
    window.addEventListener('scroll', onScroll);
  });

  return { backToTopBtnRef, showBackToTopBtn };
};
