import { createRouter, createWebHistory } from 'vue-router';
import ProductPage from '../views/ProductPage.vue';

const pidPath = '/:locale/p/:productId';
const skuPath = '/:locale/p/:productId?/pn/:partNumber';

const router = createRouter({
  history: createWebHistory(),
  routes: [{
    name: 'ShortPath',
    path: pidPath,
    component: ProductPage,
  }, {
    name: 'FullPath',
    path: skuPath,
    component: ProductPage,
  }],
});

export default router;
