const initState = {
  galleryImageList: [],
  valid: false,
  activeImageIndex: 0,
  loaded: false,
  promoBanner: '',
  widgetMetadata: {},
  cloudinaryAssets: [],
};

export default {
  state: { ...initState },
  getters: {
    getIsMultiImageGallery(state, getters) {
      return getters.getGalleryImageList.length > 1;
    },
    getGalleryImageList(state) {
      return state.galleryImageList;
    },
    getActiveImageIndex(state) {
      return state.activeImageIndex;
    },
    getValid(state) {
      return state.valid;
    },
    getGalleryLoaded(state) {
      return state.loaded;
    },
    getPromoBanner(state) {
      return state.promoBanner;
    },
    getWidgetMetadata(state) {
      return state.widgetMetadata;
    },
    getCloudinaryAssets(state) {
      return state.cloudinaryAssets;
    },
  },
  mutations: {
    setGalleryImageList(state, payload) {
      state.galleryImageList = payload;
    },
    setActiveImageIndex(state, payload) {
      state.activeImageIndex = payload;
    },
    setValid(state, payload) {
      state.valid = payload;
    },
    setGalleryLoaded(state, payload) {
      state.loaded = payload;
    },
    setPromoBanner(state, payload) {
      state.promoBanner = payload;
    },
    setWidgetMetadata(state, payload) {
      state.widgetMetadata = payload;
    },
    setCloudinaryAssets(state, payload) {
      state.cloudinaryAssets = payload;
    },
  },
  actions: {
    updateActiveImageIndex({ commit }, payload) {
      commit('setActiveImageIndex', payload);
    },
    setGallery({ commit }, payload) {
      const {
        defaultImage, gallery, valid, mediaGallery,
      } = payload;
      if (defaultImage && gallery && valid) {
        const imageList = [defaultImage, ...gallery];

        imageList.forEach((imageObj) => {
          // If already fixed, do nothing
          if (imageObj.imageSrc && imageObj.altText) return;

          imageObj.imageSrc = imageObj.image;
          delete imageObj.image;
          imageObj.altText = imageObj.altTag;
          delete imageObj.altTag;
        });

        commit('setGalleryImageList', imageList);
      } else {
        commit('setGalleryLoaded', true);
      }

      commit('setCloudinaryAssets', mediaGallery);
      commit('setActiveImageIndex', 0);
      commit('setValid', valid);
    },
    setGalleryLoaded({ commit }, payload) {
      commit('setGalleryLoaded', payload);
    },
    setPromoBanner({ commit }, payload) {
      commit('setPromoBanner', payload);
    },
    setWidgetMetadata({ commit }, payload) {
      commit('setWidgetMetadata', payload);
    },
  },
};
