/**
 * @param {string} optionsList - The list of EMS options with product images to filter
 * @returns {array}            - The selected EMS option text. If no match is found, then this returns ['NA']
 *
 * @example
 * getSelectedEmsOption([emsOptionsList]) => ['1']
 */
export const getSelectedEmsOptionIndex = (optionsList) => {
  if (!Array.isArray(optionsList)) {
    console.warn('Expected optionsList to be an array');
    return ['NA'];
  }

  if (!optionsList.length) {
    // If options list is an empty array, the product doesnt have variations and so the returned index should be 1
    return ['1'];
  }

  if (!optionsList?.[0]?.options || !Array.isArray(optionsList[0]?.options)) {
    console.warn('Expected optionsList options to be an array');
    return ['NA'];
  }

  const result = optionsList[0].options.filter((item) => item?.selected === true);

  // If after we filter down to the only the selected EMS option, there are no entries in the list,
  // return ['NA']
  if (result?.length === 0) {
    console.warn('Filtering for selected EMS option resulted in an empty array/no matches');
    return ['NA'];
  }

  const orderValue = result?.[0]?.order;
  const order = typeof orderValue === 'number' ? orderValue + 1 : 'NA';

  // Return array with either 'NA' or the order number converted to a string. e.g. '4'
  return [order.toString()];
};

export default getSelectedEmsOptionIndex;
