<script setup>
/* Imports */
import { computed } from 'vue';

/* Helpers */
import {
  mapActions,
  mapGetters,
  replaceSuperScript,
} from '../helpers/mainHelpers';

/* Props */
const props = defineProps({
  categoryName: {
    type: String,
    default: '',
  },
});

/* Store - Getters */
const {
  getCategoryImageByCategory: categoryImage,
  getActiveSection: sectionSelected,
} = mapGetters();

/* Store - Actions */
const { setActiveSection } = mapActions();

const computedProductCategory = computed(() => replaceSuperScript(props.categoryName));
</script>

<template>
  <div>
    <div
      v-show="!sectionSelected"
      class="app__product__accessories__section__card"
    >
      <div
        class="app__product__accessories__section__card-content"
        @click="setActiveSection(props.categoryName)"
      >
        <img
          :src="categoryImage(props.categoryName)"
          :alt="props.categoryName"
          loading="lazy"
          width="130"
          height="130"
        >
        <g-heading
          class="app__product__accessories__section__card-content__heading"
          heading-size="4"
          text-align="center"
          :content="computedProductCategory"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.app__product__accessories__section {
  width: 100%;
  margin: 0;

  @include breakpoint(xs) {
    width: calc(50% - 0.75em);
  }

  @include breakpoint(sm) {
    width: 100%;
  }

  @media screen and (min-width: 960px) {
    width: calc(50% - 0.75em);
  }

  @include breakpoint(lg) {
    width: calc(33.33% - 1em);
  }

  &__card {
    padding: 1em;
    cursor: pointer;
    height: 100%;
    @include make-drop-shadow();

    &__link:link,
    &__link:visited,
    &__link:hover,
    &__link:active {
      color: $color-black !important; // stylelint-disable-line
      text-decoration: none;
    }
  }

  &__card-content {
    @include font-primary();
    display: flex;
    flex-flow: column;
    height: 100%;
    color: $color-black;

    img {
      width: 8.125rem;
      margin: 0 auto 0.5em;

      @include breakpoint(sm) {
        margin: 0 auto 1em;
      }
    }

    &__heading {
      margin: 0;
    }

    &__subheading {
      margin: 0;
    }
  }

  div &__cart__button {

    a {
      color: $color-white;
      text-decoration: none;
      background-color: $color-yellow-50;
      /* stylelint-disable-next-line  max-line-length */
      background-image: linear-gradient(to top, $color-yellow-50 0, lighten($color-yellow-50, 10%) 100%);
      border: $color-yellow-50;
      border-style: solid;
      border-width: 1px;
      text-shadow: 0 -1px 1px rgba(0, 0, 0, 0.25);
      /* stylelint-disable-next-line  max-line-length */
      box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.05), inset 0 0 1px 1px rgba(255, 255, 255, 0.2), inset 0 1px 0 rgba(255, 255, 255, 0.25);
      font-size: 0.75rem;
      font-weight: bold;
      line-height: 18px;
      border-radius: 2px;
      padding: 0 6px;
      margin-top: 0.5em;
      display: inline-block;

      &:hover {
        background-color: $light-orange;
        text-decoration: none;
        color: $color-white;
      }
    }
  }

  &__content {
    font-size: 0.875rem;
  }

  &__partNumber {
    line-height: $font-line-height;
    color: $color-black;
  }

  &__pricing {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    color: $color-black;

    .currency-sign {
      top: 0;
    }

    &__loader {
      width: 45px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__price {
    line-height: 20px;

    &--strike {
      font-size: 0.75em;
      text-decoration: line-through;
    }
  }

  &__savings {
    margin-top: 0.5em;

    .app__product__price__formatted {
      display: inline;
    }
  }

  &__vat-tooltip {
    margin-top: 0.5em;
    font-size: 0.8em;
  }
}
</style>
