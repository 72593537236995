<script>
/* Imports */
import { mapActions } from 'vuex';

export default {
  name: 'OverviewContainer',
  mounted() {
    // If this component is found in the template than use the new full-width layout
    this.setNewOverviewTab(true);
  },
  beforeUnmount() {
    this.setNewOverviewTab(false);
  },
  methods: {
    ...mapActions(['setNewOverviewTab']),
  },
};
</script>

<template>
  <div>
    <slot />
  </div>
</template>
