/* Helpers */
import {
  request,
  findCategory,
  setMapsData,
  handleWorld,
  handleViewAll,
  sortRegionCategory,
} from '../helpers/mainHelpers';

export default {
  state: {
    selectedRegion: '',
    selectedCategory: '',
    defaultCategory: '',
    errorMessage: '',
    regionList: [],
    categoryList: [],
    mapCardsList: [],
    productData: {},
    mapsTabTranslations: {},
    mapsTabHeadingTranslations: {},
  },

  getters: {
    getSelectedRegion(state) {
      return state.selectedRegion;
    },
    getSelectedCategory(state) {
      return state.selectedCategory;
    },
    getRegionList(state) {
      return state.regionList;
    },
    getCategoryList(state) {
      return state.categoryList;
    },
    getMapCardsList(state) {
      return state.mapCardsList;
    },
    getProductData(state) {
      return state.productData;
    },
    getDefaultCategory(state) {
      return state.defaultCategory;
    },
    getMapsTabTranslations(state) {
      return state.mapsTabTranslations;
    },
    getMapsTabHeadingTranslations(state) {
      return state.mapsTabHeadingTranslations;
    },
    getMapsTabErrorMessage(state) {
      return state.errorMessage;
    },
    getIsError(state) {
      return state.regionList?.length === 0;
    },
  },
  mutations: {
    setOnloadRegionCategory(state) {
      const onloadRegion = window?.Region;
      const regionToFind = state?.mapsTabTranslations?.[onloadRegion] ?? '';
      // Set fallback to 'All' if onload region doesn't contain any maps
      state.selectedRegion = (state.regionList.find((region) => region === regionToFind)) ? regionToFind : state.defaultCategory;
      state.selectedCategory = state.defaultCategory;
    },
    setProductData(state, payload) {
      state.productData = payload;
    },
    setRegionList(state) {
      state.regionList = [];
      state.productData.forEach((value, key) => {
        state.regionList.push(key);
      });
      // Sort the regions alphabetically, add the View All to the top of the list
      state.regionList = sortRegionCategory(state.regionList, state.defaultCategory, 0);
    },
    setCategory(state, payload) {
      state.selectedCategory = payload;
    },
    setDefaultCategory(state, payload) {
      state.defaultCategory = payload ?? '';
    },
    setCurrentRegion(state, payload) {
      state.selectedRegion = payload;
    },
    updateCategories(state) {
      state.categoryList = [];
      const region = state.selectedRegion;
      const categoryData = state.productData.get(region);
      let categoryArray = [];
      categoryData?.forEach((item) => {
        categoryArray.push(item?.category);
      });
      // Sort the categories alphabetically, add View All to the end of the list
      categoryArray = sortRegionCategory(categoryArray, state.defaultCategory, categoryArray.length);
      // Handle case of changing the default category to the first element of the array if there's only 1 item
      if (categoryArray.length === 1) {
        [state.selectedCategory] = categoryArray;
      }
      categoryArray?.forEach((item) => {
        const isActive = (item === state.selectedCategory);
        state.categoryList.push({ categoryLabel: item, isActive, key: item });
      });
    },
    updateMaps(state) {
      const region = state.selectedRegion;
      const category = state.selectedCategory;
      const data = state.productData.get(region);
      const categoryIdx = findCategory(category, data);
      /* Refresh maps card only if new region contains category */
      if (categoryIdx >= 0) {
        state.mapCardsList = data[categoryIdx]?.maps;
      }
    },
    setMapsTabTranslations(state, payload) {
      /* Object is used to Map the Ecomm API call keys to the Contentful keys */
      const regionTranslations = payload?.ITFE_PRODUCT_PAGES_MAP_TAB_REGION_TRANSLATIONS;
      const categoryTranslations = payload?.ITFE_PRODUCT_PAGES_MAP_TAB_CATEGORIES_TRANSLATIONS;

      const translations = {
        'North America': regionTranslations?.ITFE_PRODUCT_PAGES_MAP_TAB_REGION_NORTH_AMERICA ?? '',
        'South America': regionTranslations?.ITFE_PRODUCT_PAGES_MAP_TAB_REGION_SOUTH_AMERICA ?? '',
        Europe: regionTranslations?.ITFE_PRODUCT_PAGES_MAP_TAB_REGION_EUROPE ?? '',
        Asia: regionTranslations?.ITFE_PRODUCT_PAGES_MAP_TAB_REGION_ASIA ?? '',
        Australia: regionTranslations?.ITFE_PRODUCT_PAGES_MAP_TAB_REGION_AUSTRALIA ?? '',
        Africa: regionTranslations?.ITFE_PRODUCT_PAGES_MAP_TAB_REGION_AFRICA ?? '',
        'Middle East': regionTranslations?.ITFE_PRODUCT_PAGES_MAP_TAB_REGION_MIDDLE_EAST ?? '',
        all: categoryTranslations?.ITFE_PRODUCT_PAGES_MAP_TAB_VIEW_ALL ?? '',
        topo: categoryTranslations?.ITFE_PRODUCT_PAGES_MAP_TAB_TOPO ?? '',
        marine: categoryTranslations?.ITFE_PRODUCT_PAGES_MAP_TAB_MARINE ?? '',
        golf: categoryTranslations?.ITFE_PRODUCT_PAGES_MAP_TAB_GOLF ?? '',
        road: categoryTranslations?.ITFE_PRODUCT_PAGES_MAP_TAB_ROAD ?? '',
        World: 'World',
      };
      state.mapsTabTranslations = translations;
      state.errorMessage = payload?.ITFE_PRODUCT_PAGES_MAP_TAB_ERROR_TRANSLATIONS;
    },
    setMapsTabHeadingTranslations(state, payload) {
      state.mapsTabHeadingTranslations = payload;
    },
  },

  actions: {
    async loadMapsData({ commit, getters }) {
      const {
        getTranslations,
        getBuyGarminEndpoint,
        getLocale,
        getStoreCode,
        getDefaultSku,
      } = getters;

      const {
        ITFE_PRODUCT_PAGES_MAP_TAB_TRANSLATIONS: mapsTabTranslations,
      } = getTranslations || {};

      commit('setMapsTabTranslations', mapsTabTranslations);
      commit('setMapsTabHeadingTranslations', mapsTabTranslations);
      const translations = getters.getMapsTabTranslations;
      commit('setDefaultCategory', translations?.all);
      const defaultCategory = getters.getDefaultCategory;

      const productData = new Map();
      const url = `${getBuyGarminEndpoint}/ecommerceServices/productCompatibility/${getLocale}/${getStoreCode}/sku/${getDefaultSku}/all/all/compatibility/maps`;
      const requestParam = {
        url,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      };
      try {
        const response = await request(requestParam);

        response.forEach((item) => {
          if (item.productRegion !== null) {
            setMapsData(productData, item, defaultCategory, translations[item?.productRegion], translations);
          }
          // This sets the productData under the 'View All' region category
          setMapsData(productData, item, defaultCategory, defaultCategory, translations);
        });

        handleWorld(productData, defaultCategory);
        handleViewAll(productData, defaultCategory);
        commit('setProductData', productData);
        commit('setRegionList');
        commit('setOnloadRegionCategory');
        commit('updateCategories');
        commit('updateMaps');
      } catch (error) {
        console.warn('Error in fetching maps tab data: ', error);
      }
    },
    changeRegion({ commit }, payload) {
      commit('setCurrentRegion', payload);
      commit('updateCategories');
      commit('updateMaps');
    },
    changeCategory({ commit }, payload) {
      commit('setCategory', payload);
      commit('updateMaps');
    },
  },
};
