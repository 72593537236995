<script>
/* Helpers */
import { cleanEscapedQuotes } from '../helpers/mainHelpers';

export default {
  name: 'PriceText',
  props: {
    formattedPrice: {
      type: String,
      default: '',
    },
  },
  computed: {
    priceHtml() {
      return this.formattedPrice ? cleanEscapedQuotes(this.formattedPrice) : '';
    },
  },
};
</script>

<template>
  <div
    class="app__product__price__formatted"
    data-test="app__product__price__formatted"
    v-html="priceHtml"
  />
</template>

<style lang="scss">
.app__product__price__formatted {

  .currency-sign {
    @extend %currencySymbol;
  }

  .amount {
    @extend %wholeUnit;

    /* stylelint-disable-next-line */
    &+.currency-code {
      margin-left: 0.5em;
    }
  }

  .currency-code {
    @extend %currencyCode;
  }
}
</style>
