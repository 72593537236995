export const breakpoints = {
  'screen-sm': '768',
  'mobile-m': '520',
  'tablet-m': '900',
  'desktop-s': '1023',
  'desktop-m': '1115',
  'desktop-l': '1224',
  'gallery-l': '1395',
  'gallery-m': '1285',
};
