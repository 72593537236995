/* Helpers */
import { sortOrder } from '../helpers/mainHelpers';

export default {
  state: {
    optionsList: [],
    options: {},
  },
  getters: {
    getOptionsList(state) {
      return state.optionsList;
    },
    getOptionListByType(state) {
      return (key) => state.optionsList.filter((op) => op.type === key);
    },
    getOptions(state) {
      return state.options;
    },
    getAllOptionItems(state) {
      return (key) => state.options[key];
    },
  },
  mutations: {
    setOptionsList(state, payload) {
      state.optionsList = payload;
    },
    setOptions(state, payload) {
      state.options = payload;
    },
  },
  actions: {
    setProductOptions({ getters, commit }, payload) {
      const { optionsList, options } = payload;
      const { disabled, enabled, selected } = options;

      // Update the EMS options
      if (!getters.getFilterDropdownList.length) {
        optionsList.forEach((emsOpt) => {
          const disabledOption = disabled[emsOpt.id] || [];
          const enabledOption = enabled[emsOpt.id] || [];
          const allOptions = [...disabledOption, ...enabledOption];

          (emsOpt.options || []).forEach(((option) => {
            const currentOpt = allOptions.find((obj) => {
              const id = Object.keys(obj);
              return id[0] === option.id;
            });

            if (currentOpt) {
              const newSku = currentOpt[option.id].split('pn/')[1];
              /* eslint-disable prefer-destructuring */
              option.sku = newSku || option.sku;
              option.url = currentOpt[option.id];
              /* eslint-enable prefer-destructuring */
            }
          }));
        });
      }

      const orderedOptionsList = sortOrder([...optionsList]);
      const filtersOptions = {};

      const filterGroupArray = (item, groupOps) => groupOps.find((op) => op.id === item);
      const filterGroupObjectArray = (groupObj, groupOps) => Object.keys(groupObj)
        .map((key) => filterGroupArray(key, groupOps))[0];
      const setOptionsState = (group, groupOps, state, filterFunc) => (group || []).map((item) => {
        const op = filterFunc(item, groupOps);
        op[state] = true;
        return op;
      });

      orderedOptionsList.forEach((groupData) => {
        const groupId = groupData.id;

        if (Object.entries(groupData).length === 0 && groupData.constructor === Object) return;
        if (getters.getFilterDropdownList.length) return;

        // clear previous state settings
        const groupOps = groupData.options.map((item) => {
          delete item.disabled;
          delete item.enabled;
          delete item.selected;

          return item;
        });

        const disabledItems = setOptionsState(disabled[groupId], groupOps, 'disabled', filterGroupObjectArray);
        const enabledItems = setOptionsState(enabled[groupId], groupOps, 'enabled', filterGroupObjectArray);
        const selectedItems = setOptionsState(selected[groupId], groupOps, 'selected', filterGroupArray);

        // group all options for the same filter coming from disabled, enabled, selected
        filtersOptions[groupId] = [
          ...disabledItems,
          ...enabledItems,
          ...selectedItems,
        ];
        // order objects inside each filter based on their order value
        filtersOptions[groupId] = sortOrder(filtersOptions[groupId]);
      });

      commit('setOptionsList', orderedOptionsList);
      commit('setOptions', filtersOptions);
    },
  },
};
