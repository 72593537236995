<script setup>
/* Imports */
import { computed } from 'vue';

/* Analytics */
import {
  sendAnalyticsEvent,
  sanitizeAnalyticsString,
} from '../helpers/ga/analytics';

/* Helpers */
import {
  mapGetters,
  replaceSuperScript,
} from '../helpers/mainHelpers';

/* Composables */
import { useGenericAnalyticsDataHelper } from '../composables/useAnalyticsData';

/* Inject composables */
const { genericAnalyticsData } = useGenericAnalyticsDataHelper();

/* Store - Getters */
const {
  getProductVariation: productVariation,
} = mapGetters();

/* Props */
const props = defineProps({
  device: {
    type: Object,
    default: () => ({}),
  },
  compatibleDeviceIndex: {
    type: Number,
    default: 0,
  },
});

/* Handle GA4 - Tealium */
const sendCompatibleDevicesTealiumEvent = (currDevice) => {
  const analyticsData = {
    ...genericAnalyticsData(
      'Compatible Devices',
      'Compatible Devices',
    ),
    index: props.compatibleDeviceIndex + 1,
    product_id: currDevice?.productId || 'NA',
    product_brand: window?.globalAnalyticsData?.product_brand[0],
    product_variant: sanitizeAnalyticsString(productVariation.value) || 'NA',
    product_name: sanitizeAnalyticsString(currDevice?.displayName) || 'NA',
    product_url: currDevice?.link || 'NA',
    // Note: We get the product_sku for the main product only
    product_sku: '',
    // Note: We don't get the price for the Compatible Devices so we send empty strings
    product_original_price: '',
    product_price: '',
    is_sale: '',
    price_shown: 'no',
    tealium_event: 'select_item',
  };

  sendAnalyticsEvent(analyticsData);
};

const computedProductName = computed(() => replaceSuperScript(props.device?.displayName));
</script>

<template>
  <div>
    <a
      class="app__product__devices__section__link"
      :href="props.device?.link"
      @click="sendCompatibleDevicesTealiumEvent(props.device)"
    >
      <div class="app__product__devices__section__card app__product__devices__section__drop-shadow">
        <div class="app__product__devices__section__card-content">
          <img
            :src="props.device?.image"
            :alt="props.device?.displayName"
            loading="lazy"
            width="130"
            height="130"
          >
          <g-heading
            class="app__product__devices__section__card-content__heading"
            heading-size="4"
            text-align="center"
            :content="computedProductName"
          />
        </div>
      </div>
    </a>
  </div>
</template>

<style lang="scss">
.app__product__devices__section {
  width: 100%;

  @include breakpoint(xs) {
    width: calc(50% - 0.75em);
  }

  @include breakpoint(md) {
    width: calc(25% - 1.125em);
  }

  &__link {
    display: block;
    position: relative;
    color: $color-black;
    text-decoration: none;
    height: 100%;

    &:visited,
    &:hover,
    &:active {
      color: $color-black !important; // stylelint-disable-line
      text-decoration: none;
    }
  }

  &__card {
    padding: 1em;
    cursor: pointer;
    height: 100%;

    @include breakpoint(sm) {
      padding: 1.3em 2em;
    }

    &-content {
      @include font-primary();
      display: flex;
      flex-flow: column;
      height: 100%;

      img {
        width: 130px;
        margin: 0 auto 0.5em;

        @include breakpoint(sm) {
          margin: 0 auto 1em;
        }
      }

      &__heading {
        margin: 0;
        word-break: break-word;
      }
    }
  }

  &__drop-shadow {
    height: 100%;
    width: 100%;
    padding-top: 1rem;
    @include make-drop-shadow();
  }
}
</style>
